/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Ubuntu, "Helvetica Neue", sans-serif  !important; }

.mat-menu-panel.my-full-width-menu {
    max-width: none;
    width: 50vw;
    margin-left: -8px;
    margin-top: 24px;
  }

  /* , "Helvetica Neue", sans-serif; */